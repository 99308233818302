<template>
	<div class="main-main-input-cont">
		<div class="main-input-main-cont relative">
			<!-- <div class="main-input-gradient"></div> -->
		  <form @submit="onSubmit" class="grid-horizontal main-input-container rounded relative" data-gap="none" autocomplete="off">
		  	<button @click="onSubmit" aria-label="search button"><base-icon class="stack-horizontal" data-align="center" data-justify="center" name="search" color-stroke="transparent" color-fill="white" alt="search button" /></button>
		  	<input type="text" v-model="textInput" :placeholder="$t('homepage_cerca_home')" class="text--subtitle" name="main-text-input">
		  </form>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'InterfaceMainInput',
	props: {
		customCb: {
			type: Function,
			required: false
		}
	},
	data() {
		return {
			textInput: ''
		}
	},
	computed: {
		...mapState({
			mobile: state => state.interface.mobile
		})
	},
	methods: {
		onSubmit(e) {
			e.preventDefault();
			console.log(this.textInput);
			if (!this.customCb) this.$router.push(`/explore?search=${this.textInput}`)
			else this.customCb(this.textInput);
		}
	}
}
</script>

<style lang="scss">

	@import '@css/variables.scss';

	.main-main-input-cont {
		border-radius: var(--radius);
		overflow: hidden;
		box-shadow: var(--shadow-big);
		// padding: -2px;
	}

	.main-input-main-cont {
		overflow: hidden;
		background-color: transparent;
		margin: -2px;
	}

	.main-input-container {
		// box-shadow: var(--shadow-big);
		overflow: hidden;
		--flow-space-horizontal: 0;
		gap: 0;
		background-color: transparent;
		height: calc(var(--s4) + 1.5rem);
		grid-template-columns: min-content 1fr;
		button {
			display: table;
			aspect-ratio: 1/1;
			height: 100% !important;
			width: auto !important;
			min-width: calc(var(--s4) + 1.5rem);
			min-height: calc(var(--s4) + 1.5rem);
		}
		.icon {
			background-color: var(--color-primary-75);
			// padding: 2vw;
			height: 100% !important;
			width: auto !important;
			// padding-left: 1.1vw;
			overflow: hidden;
			> svg {
				width: 40%;
				height: auto;
			}
		}

		input {
			background-color: var(--color-primary-10);
			color: var(--color-black-text);
			// width: 100%;
			// padding: var(--s2) var(--s1);
			// margin-left: 0.8rem;
			padding-left: 1.5rem;
			border-radius: 0;
			margin-left: -0.5rem;
			margin-right: -0.5rem;
			transition: background-color $transition-speed;

			&::placeholder {
				color: var(--color-black-text);
				opacity: 1;
			}
			&:hover {
				background-color: var(--color-primary-25);
			}
			&:focus {
				background-color: var(--color-primary-75);
				color: var(--color-black-text);
				&::placeholder {
					color: var(--color-black-text);
					opacity: 1;
				}
			}
		}
	}

	.main-input-gradient {
		position: absolute;
		top: calc(var(--s4) * -1);
		width: 100%;
		bottom: calc(var(--s4) * -1);
		z-index: -1;
		background: linear-gradient(to bottom, #ffffff00 0, #ffffffff var(--s4), #ffffffff calc(100% - var(--s4)), #ffffff00 100%);
	}

	@media (max-width: $breakpoint-m) {
		.main-input-container {
			height: var(--s4);
		}
		.main-main-input-cont {
			box-shadow: var(--shadow-small);
		}
		.main-input-container button {
			min-width: var(--s4);
			min-height: var(--s4);
		}
	}
</style>
