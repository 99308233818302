<template>
	<div class="dgrid-body dgrid-container dgrid-aside-wrap rounded bg-black big-shady newsletter-home-container" data-gap="none" style="--dgrid-aside-a:4fr;--dgrid-aside-b:8fr;">
		<base-animated-image :images="images" class="newsletter-images"/>
		<div class="stack-vertical newsletter-content-text" data-justify="spaced">
			<div class="newsletter-text stack-vertical">
				<h3>{{$t('homepage_titolo_sez_newsletter')}}</h3>
				<p class="text--body1">{{$t('homepage_testo_sez_newsletter')}}</p>
			</div>
			<div class="newsletter-form stack-horizontal">
				<!-- <input type="text" class="half-radius" :placeholder="$t('subscribe_newsletter')" name="newsletter_subscription_home" v-model="emailNewsletter"> -->
				<iframe :src="`./mailchimp_sub.html?lang=${$i18n.locale}`" title="newsletter" scrolling="no" frameborder="0" id="newsletter-iframe"></iframe>
				<!-- <base-button type="tertiary-negative" :fit-width="mobile" :text="$t('homepage_bottone_sez_newsletter')" @clicked="subscribe"/> -->
			</div>
		</div>
	</div>
</template>

<script>
import API from '@js/API.js';
import { mapState } from 'vuex';
import globals from '@js/globals.js';
import utils from '@js/utils.js';
export default {
	name: 'InterfaceNewsletter',
	data() {
		return {
			images: [
				{
					src: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_NL.png')),
					animations: 'bottom',
					alternativeText: 'stampo'
				},
				{
					src: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_NL2.png')),
					animations: 'top',
					alternativeText: 'cavatappi'
				},
				{
					src: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_NL3.png')),
					animations: 'bottom',
					alternativeText: 'forchetta'
				},
				{
					src: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_NL4.png')),
					animations: 'top',
					alternativeText: 'padella'
				}
			],
			emailNewsletter: ''
		}
	},
	computed: {
		...mapState({
			mobile: state => state.interface.mobile
		})
	},
	methods: {
		subscribe() {
			const data = new FormData();
			data.set('EMAIL', 'prasova@dotdotdot.it');
			fetch('https://beniculturali.us12.list-manage.com/subscribe/post-json?u=8b1147f82bdddcc065f152f43&amp;id=bc2229b8da', {
				method: 'POST',
				// mode: 'no-cors',
				headers: {
	      },
				body: data
			})
			// .then(res => res.text())
			.then(res => {
				console.log('res', res)
			})
			.catch(err => console.log('err', err))
			// let authenticationString = btoa('anystring:'+globals.EMAIL_API_KEY);
	  //   authenticationString = "Basic " + authenticationString;

	  //   fetch('https://us12.api.mailchimp.com/3.0/lists/'+ globals.EMAIL_AUDIENCE_ID +'/members', {
	  //     mode: 'no-cors',
	  //     method: 'POST',
	  //     headers: {
	  //       'authorization': authenticationString,
	  //       'Accept': 'application/json',
	  //       'Content-Type': 'application/json'
	  //     },
	  //     body: JSON.stringify({
			// 		"email_address": 'davide@dotdotdot.it',
			// 		status: 'subscribed'
			// 		// "email_address": this.emailNewsletter,
	  //     })
	  //   }).then(function(e){
	  //       console.log("fetch finished")
	  //   }).catch(function(e){
	  //       console.log("fetch error");
	  //   })
		}
	},
	mounted() {
		document.querySelector(".newsletter-home-container iframe").contentDocument.body.style.fontFamily = "SuisseRegular";
	}
}
</script>

<style lang="scss">

	@import '@css/variables.scss';

	.newsletter-home-container {
		overflow: hidden;
	}

	.newsletter-content-text {
		color: var(--color-white);
		padding: var(--s2);
		--flow-space-horizontal: var(--s2);
	}

	.newsletter-images {
		background-color: var(--color-thin);
	}

	.newsletter-form {
		--flow-space-horizontal: var(--s3);
	}

	.newsletter-text {
		gap: var(--s2);
	}

	[name="newsletter_subscription_home"][type="text"] {
		background-color: var(--color-white);
		flex-grow: 1;
		padding: 0 var(--s3);
		// height: var(--s4);
	}

	.newsletter-home-container iframe {
		position: relative;
		width: 100%;
		height: 100%;
		display:block;
		// border: 0px;
	}

	@media (max-width: $breakpoint-m) {
		// .newsletter-home-container {
		// 			--dgrid-aside-template: "a" 
		// 															"b";
		// }

		[name=newsletter_subscription_home][type=text] {
			padding: var(--s1) var(--s1);
		}
		.newsletter-form {
			flex-wrap: wrap;
			> * {
				width: 100%;
			}
		}
	}
</style>
