<template>
  <main id="home" class="flow ge-page relative">
    <section class="dgrid-body dgrid-container home-header-grid-block">
      <section class="page-header home-two-blocks image-left relative">
        <div class="page-header-carousel-container relative media">
          <img v-for="(image, i) in defImagesHeader[currentCarousel]" class="page-header-carousel-images" 
            :src="image.url"
            :alt="image.name"
            :style="stylesHeaderImages[i]"
          >
        </div>
        <div class="home-page page-title relative" :key="localeComputed">
          <!-- <h2 id="homepage-title">{{$t("homepage_header_principale")}}</h2> -->
          <h2 id="homepage-title-fake">{{$t("homepage_header_principale")}}</h2>
          <vue-typed-js 
            :strings="[$t('homepage_header_principale')]" 
            :show-cursor="false"
            :type-speed="5"
            @onComplete="onCompleteWriting">
            <h2 class="typing"></h2>
          </vue-typed-js>
        </div>
        <img v-if="!mobile" class="scroll-image" alt="scroll-down" :src="require('@assets/icons/arrow-move-down.svg')" @click="handleScrollImageClick">
      </section>
    </section>
    <!-- SEARCH PART -->
    <section class="main-input dgrid-body">
      <interface-main-input id="home-main-input" />
    </section>
    <!-- QUALITY VOICES/ CATEGORIES -->
    <section class="has-dgrid-scrollx-padding">
      <interface-cards-carousel 
        :title="$t('homepage_titolo_sez_categorie')"
        see-all-link
        with-shadow
        see-all-href="topics"
        @see-all-clicked="$router.push({name: 'topics'})">
        
        <template v-if="!featuredTopics.length">
          <base-cg-topic
            v-for="i in 4"
            :key="i"
            skeleton
          />
        </template>

        <template v-else>
          <a v-for="topic in featuredTopics" :href="`explore?topic=${topic.name}`" :key="topic.id" @click.prevent>
            <base-cg-topic     
              :item="topic"
              @clicked="$router.push({name: 'explore', query: {topic: topic.name}})"
            />            
          </a>
        </template>

      </interface-cards-carousel>
    </section>
    <!-- EXPLORE -->
    <section id="home-explore" class="explore full-width grid-horizontal bordered-top bordered-bottom home-description-container">
<!--       <interface-map
        ref="map"
        mobile
      /> -->
      <div :class="(mobile ? '' : 'bordered-right')+' description stack-vertical'">
        <h3>{{$t('homepage_titolo_sez_mappa')}}</h3>
        <p>{{$t('homepage_testo_sez_mappa')}}</p>
        <a href="explore" @click.prevent><base-button v-if="!mobile" type="secondary" :text="$t('homepage_bottone_sez_mappa')" @clicked="goToExplore" /></a>
      </div>
      <div class="description-image">
        <!-- <img class="decription-image-box" src="@assets/video/Video_mappa_homepage_1.gif"/> -->
        <video v-if="!mobile" src="@assets/video/Video mappa-homepage_2.mp4" muted autoplay playsinline loop></video>
        <video v-else src="@assets/video/Video mappa-homepage_2_mobile.mp4" muted autoplay playsinline loop></video>
      </div>
      <a href="explore" @click.prevent><base-button v-if="mobile" type="secondary" fit-width :text="$t('homepage_bottone_sez_mappa')" @clicked="goToExplore"/></a>
    </section>
    <!-- DATABASE CAROUSEL -->
    <section v-if="featuredDatabases.length">
      <interface-cards-carousel
        with-shadow
        :title="$t('homepage_titolo_sez_database')"
        >
        
        <template v-if="!featuredDatabases.length">
          <base-cg-database
            v-for="i in 4"
            :key="i"
            skeleton
          />
        </template>

        <template v-else>
          <a v-for="db in featuredDatabases" :href="`explore/?database=${db.name}`" :key="db.id" @click.prevent>
            <base-cg-database
              :item="db"
              @clicked="$router.push({name: 'explore', query: {database: db.name}})"
            />            
          </a>
        </template>        

      </interface-cards-carousel>
    </section>
    <!-- MICRONARRAZIONI -->
    <section id="home-micronarrazioni" class="grid-horizontal home-description-container bg-black">
      <div class="description stack-vertical">
        <h3>{{$t('homepage_header_v2')}}</h3>
        <p>{{$t('homepage_testo_sez_micronarrazioni')}}</p>
        <a :href="'explore?data_type=micronarrazione'" @click.prevent>
          <base-button v-if="!mobile" type="negative" :text="$t('homepage_bottone_sez_micronarrazioni')" @clicked="$router.push({name: 'explore', query: {data_type: 'micronarrazione'}})"/>
        </a>
      </div>
      <div class="description-image">
        <img class="decription-image-box" alt="gif micronarrazioni" src="@assets/video/video_micronarrazioni.gif"/>
      </div>
      <a :href="'explore?data_type=micronarrazione'" @click.prevent>
        <base-button v-if="mobile" type="negative" fit-width :text="$t('homepage_bottone_sez_micronarrazioni')" @clicked="$router.push({name: 'explore', query: {data_type: 'micronarrazione'}})"/>
      </a>
    </section>
    <!-- STORIES -->
    <section class="home-events-and-stories" v-if="featuredStoriesLoading || featuredStories.length">
      <interface-cards-carousel 
        :title="$t('homepage_titolo_sez_storie')"
        :columns="2"
        see-all-link
        see-all-href="stories"
        @see-all-clicked="$router.push({name: 'stories'})"
        >
        <template v-if="featuredStoriesLoading">
          <base-cs-story
            v-for="i in 2" 
            :key="i"
            :skeleton-lines="2"
            skeleton
          />          
        </template>

        <template v-else>
          <a v-for="item in featuredStories" :href="`stories/${item.slug}`" :key="item.id" @click.prevent>
            <base-cs-story
              :item="item"
              @clicked="$router.push({name: 'story', params: {slug: item.slug}})"
            />
          </a>         
        </template>

      </interface-cards-carousel>
    </section>

    <!-- EVENTS --> 
    <section class="home-events-and-stories" v-if="featuredEventsLoading || featuredEvents.length">
      <interface-cards-carousel 
        :title="$t('homepage_titolo_sez_eventi')"
        :columns="2"
        see-all-link
        see-all-href="events"
        @see-all-clicked="$router.push({name: 'events'})"
        >
        <template v-if="featuredEventsLoading">
          <base-cs-event
            v-for="i in 2" 
            :key="i"
            skeleton
          />          
        </template>

        <template v-else>
          <a v-for="item in featuredEvents" :href="`events/${item.slug}`" :key="item.id" @click.prevent>
            <base-cs-event
              :item="item"
              @clicked="$router.push({name: 'event', params: { slug: item.slug }})"
            />             
          </a>
        </template>

      </interface-cards-carousel>
    </section>
    <!-- QUALITY VOICES -->
    <section class="home-quality-voices" v-if="featuredSheetsPick.length">
      <div class="dgrid-body">
        <h3>{{$t('homepage_titolo_sez_voci_di_qualità')}}</h3>
      </div>
      <div v-if="featuredSheetsPick.length == 2" class="home-voices grid-horizontal bordered-top bordered-bottom home-description-container cards-container">
        <div :class="(tablet ? 'bordered-bottom' : 'bordered-right')+' description-image'">
          <a :href="`sheet/${featuredSheetsPick[0].slug}`" @click.prevent>
            <base-cs-sheet
              type="featured"
              :item="featuredSheetsPick[0]"
              @clicked="$router.push({name: 'sheet', params: {id: featuredSheetsPick[0].id.toString(), slug: featuredSheetsPick[0].slug}})"
            />            
          </a>
        </div>
        <div class="description-image">
          <a :href="`sheet/${featuredSheetsPick[1].slug}`" @click.prevent>
            <base-cs-sheet
              type="featured"
              :item="featuredSheetsPick[1]"
              @clicked="$router.push({name: 'sheet', params: {id: featuredSheetsPick[1].id.toString(), slug: featuredSheetsPick[1].slug}})"
            />            
          </a>
        </div>
      </div>
    </section>
    <!-- REGIONS CAROUSEL -->
    <section>
      <interface-cards-carousel
        with-shadow
        :title="$t('homepage_titolo_sez_regioni')"
        >

        <template v-if="!regionsReady">
          <base-cg-region
            v-for="i in 4"
            :key="i"
            skeleton
          />
        </template>

        <template v-else>
          <a v-for="region in featuredRegions" :href="`explore?region=${region.name}`" :key="region.id" @click.prevent>
            <base-cg-region
              :item="region"
              @clicked="$router.push({name: 'explore', query: {region: region.name}})"
            />            
          </a>      
        </template>

      </interface-cards-carousel>
    </section>
    <!-- REGISTRATION -->
    <section class="grid-horizontal home-description-container  bordered-top bordered-bottom home-registration">
      <div :class="(mobile ? '' : 'bordered-right')+' description-image'">
        <img alt="immagine registrazione" src="@assets/images/home_registration.png"/>
      </div>
      <div class="description stack-vertical dgrid-body">
        <h3>{{$t('homepage_titolo_sez_partecipa')}}</h3>
        <p>{{$t('homepage_testo_sez_partecipa')}}</p>
        <a href="takepart" @click.prevent>
          <base-button v-if="!mobile" type="tertiary" :text="$t('homepage_bottone_sez_partecipa')" @clicked="$router.push({name: 'takepart'})"/>
        </a>
      </div>
      <a href="takepart" @click.prevent>
        <base-button v-if="mobile" type="tertiary" fit-width :text="$t('homepage_bottone_sez_partecipa')" @clicked="$router.push({name: 'takepart'})"/>
      </a>
    </section>
    <!-- NEWSLETTER -->
    <section>
      <interface-newsletter/>
    </section>
<!--     <section class="dgrid-container">
      <div>{{$t('homepage_titolo_sez_partner')}}</div>
    </section> -->
  </main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import InterfaceMainInput from '@components/InterfaceMainInput.vue';
import InterfaceCardsCarousel from '@components/InterfaceCardsCarousel.vue';
import InterfaceMap from '@components/InterfaceMap.vue';
import InterfaceNewsletter from '@components/InterfaceNewsletter.vue';
import collages from '@js/collages.js';
import utils from '@js/utils.js';

export default {
  name: 'ViewHome',
  components: { 
    InterfaceMainInput, 
    InterfaceCardsCarousel, 
    InterfaceMap,
    InterfaceNewsletter,
  },
  data() {
    return {
      defImagesHeader: [
        [ 
          {name: 'bilancia', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_01_bilancia.png'))},
          {name: 'spaghetti', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_01_spaghetti.png'))},
          {name: 'forchetta', url: require('@assets/images/collages/GECA_home_01_forchetta.svg')}
        ],
        [ 
          {name: 'gavetta', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_02_gavetta.jpg'))},
          {name: 'moneta', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_02_moneta.jpg'))},
          {name: 'forchetta', url: require('@assets/images/collages/GECA_home_01_forchetta.svg')}
        ],
        [ 
          {name: 'cozza', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_03_cozza.jpg'))},
          {name: 'pescatori', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_03_pescatori.jpg'))},
          {name: 'amo', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_03_amo.jpg'))}
        ],
        [ 
          {name: 'forchetta', url: require('@assets/images/collages/GECA_home_01_forchetta.svg')},
          {name: 'formaggio', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_04_formaggio.jpg'))},
          {name: 'cavatappi', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_04_cavatappi.jpg'))}
        ],
        [ 
          {name: 'napoletana', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_05_napoletana.jpg'))},
          {name: 'pane', url: require('@assets/images/collages/'+utils.manageImagesExtension('GECA_home_05_pane.jpg'))},
          {name: 'forchetta', url: require('@assets/images/collages/GECA_home_01_forchetta.svg')}
        ],
      ],
      stylesHeaderImages: [
        'right: 0; bottom: 0; animation-delay: 0s;',
        'left: 0; top: 0; bottom: 0; margin: auto; animation-delay: 0.05s;',
        'right: 0; top: 0; animation-delay: 0.1s;',
      ],
      // currentCarousel: 2,
      currentCarousel: Math.round((Math.random()*4)),
      featuredSheetsPick: [],
      featuredRegions: [],
      // inHome: true
    }
  },
  computed: {
    ...mapGetters("cms", ["listFeatured", "loadingByListName"]),
    ...mapState("cms", ["topics"]),
  	...mapState({
  		sheets: state => state.sheets,
      mobile: state => state.interface.mobile,
      tablet: state => state.interface.tablet,
      featuredSheets: state => state.sheets.featured
  	}),
    featuredEvents() {
      return this.listFeatured('events').slice(0,2);
    },
    featuredStories() {
      return this.listFeatured('stories').slice(0,2);
    },
    featuredEventsLoading() {
      return this.loadingByListName('events');
    },
    featuredStoriesLoading() {
      return this.loadingByListName('stories');
    },
    featuredTopics() {
      return this.topics.filter(el => {
        let spec = collages.topics.find(t => t.name == el.name);
        return spec && spec.featured;
      });
    },
    featuredDatabases() {
      return this.listFeatured('databases').slice(0,4);
    },
    regionsReady() {
      return this.featuredRegions.length && this.featuredRegions.every(el => !isNaN(el.sources));
    },
    inHome() {
      return this.$route.name == 'home';
    },
    localeComputed() {
    	return this.$i18n.locale
    }
  },
  methods: {
  	...mapActions("sheets", ["getFeaturedSheets", "getSheets"]),
    ...mapActions("cms", ["getListFeatured", "getList"]),
    goToExplore() {
      this.$router.push('/explore')
    },
    onCompleteWriting() {
      let me = new Event('mouseenter')
      let ml = new Event('mouseleave')
      document.querySelector('#home .page-header-carousel-images').dispatchEvent(me);
      setTimeout(() => {
        document.querySelector('#home .page-header-carousel-images').dispatchEvent(ml);
      }, 500)
    },
    pickFeaturedSheets() {
      // get 2 random featured sheets
      let count = this.featuredSheets.length;
      let rtn = [];
      while (rtn.length < 2) {
          let rand = Math.floor(Math.random() * count);
          if (!rtn.map(el=>el.id).includes(this.featuredSheets[rand].id)) {
            rtn.push(this.featuredSheets[rand]);
          }
      }
      this.$set(this, "featuredSheetsPick", rtn);
    },
    getData() {
      // featured sheets
      if (!this.featuredSheets.length) {
        this.getFeaturedSheets()
            .then(()=>{
              this.pickFeaturedSheets();
            });
      } else {
        this.pickFeaturedSheets();
      }
      
      // featured events
      this.getListFeatured('events');

      // featured stories
      this.getListFeatured('stories');

      // featured topics 
      this.getList('topics');

      // featured topics 
      this.getListFeatured('databases');

      // featured regions
      this.getFeaturedRegions();
    },
    async getFeaturedRegions() {
      // TODO TEMP UNTIL THERE WILL BE AN API ENDPOINT /REGIONS
      let regions = collages.regions.filter(el => el.featured);

      await regions.forEach(async r => {
        let res = await this.getSheets({page_size: 1, region: r.name});
        this.$set(r,"sources",res.results);
      })

      this.$set(this, "featuredRegions", regions);
    },
    handleScrollImageClick() {
      window.scrollTo({top: document.querySelector('#home-main-input').offsetTop - 100, behavior: 'smooth'})
    },
    startHomeCollageAnimations() {
      let maxOffset = 20;
      let element = document.querySelector('#home .page-header');
      let currScrollPosition = 0;

      element.onmousemove = e => {
        if(this.inHome){
          if(!currScrollPosition) {
            let images = document.querySelectorAll('#home .page-header-carousel-images')
            let newX = (e.clientX/element.clientWidth)*maxOffset;
            let newY = (e.clientY/element.clientHeight)*maxOffset;
            images[0].style.transform = `translate(${(newX)}px, ${(newY)}px)`
            images[1].style.transform = `translate(${(newX)}px, ${(-newY)}px)`
            images[2].style.transform = `translate(${(-newX)}px, ${(newY)}px)`
          }
        }
      }
      element.onmouseenter = e => {
        if(this.inHome){
          document.querySelectorAll('#home .page-header-carousel-images').forEach(el => {
            if(!currScrollPosition) {
              el.classList.remove('reset');
              el.classList.remove('animation');
            }
          })
        }
      }
      element.onmouseleave = e => {
        if(this.inHome){
          document.querySelectorAll('#home .page-header-carousel-images').forEach(el => {
            if(!currScrollPosition) {
              el.classList.add('reset');
              el.classList.remove('animation');
            }
          })
        }
      }

      document.body.onscroll = e => {
        if(this.inHome){
          if(window.pageYOffset <= window.screen.height){
            currScrollPosition = window.pageYOffset;
            let normalizedScroll = window.pageYOffset / window.screen.height
            let images = document.querySelectorAll('#home .page-header-carousel-images')
            if(images[0].classList.contains('reset')) {
              images.forEach(img => img.classList.remove('reset'))
            }
            if(!this.tablet){
              images[0].style.transform = `translateY(${normalizedScroll*15}rem)`
              images[1].style.transform = `translateY(${normalizedScroll*17}rem)`
              images[2].style.transform = `translateY(${normalizedScroll*20}rem)`
            }

            let scrollImage = document.querySelector('.scroll-image');
            if (scrollImage) {
              scrollImage.style.transform = `translate(-50%, ${-normalizedScroll*10}rem)`;
            }
          }
        }
      }
    }
  },
  mounted() {
    // console.log('mounted')
    this.getData();
    this.startHomeCollageAnimations();
    // this.inHome = true;
  },
  beforeDestroy() {
    // this.inHome = false;
    // remove all listeners
    // window.scrollTo({top: 0, behavior: 'smooth'})
    // document.querySelector('#home .page-header').onmousemove = null
    // document.querySelector('#home .page-header').onmouseenter = null
    // document.querySelector('#home .page-header').onmouseleave = null
    // document.body.onscroll = null

    // document.querySelectorAll('#home .page-header-carousel-images').forEach(img => img.style.transform = 'translate(0,0)')
    // document.body.onscroll = null
  }
}

</script>

<style lang="scss">
  @import '@css/variables.scss';
  @import '@css/commons/pages.scss';

  #home {
    --flow-space-horizontal: var(--s5);
    --flow-space: var(--s5);
    padding-bottom: var(--s5);
  }

  #home > section:nth-child(3) {
    margin-top: var(--s5);
  }

  section.has-dgrid-scrollx-padding + section {
    margin-top: calc(var(--flow-space) - var(--s4));
  }

  .home-description-container {
    --flow-space-horizontal: 0;
    grid-template-columns: repeat(auto-fit, 50%);
    .description {
      padding: var(--s1) 0; 
      --flow-space-horizontal: var(--s4);
    }
  }

  .home-description-container > .description-image {
    padding: var(--s3);
    border-radius: var(--radius);
    overflow: hidden;
  }
  .home-description-container.cards-container > .description-image, 
  .home-registration > .description-image {
    border-radius: 0;
    overflow: hidden;
  }
  .home-description-container > .description-image > .decription-image-box {
    aspect-ratio: 1/1;
  }
  .home-description-container > .description-image > * {
    width: 100%;
  }
  .home-description-container > .description-image > img,
  .home-description-container > .description-image > video {
    border-radius: var(--radius);
    overflow: hidden;
  }  

  #home-micronarrazioni {
    color: var(--color-white);
  }

  .home-events-and-stories {
    --dgrid-fix-col: 2;
    --dgrid-min-width: 40%;
  }

  .home-voices .card {
    max-width: 100%;
  }

  .home-registration {
    background-color: var(--color-primary-25);
  }

  .home-quality-voices h3 {
    margin-bottom: var(--s3);
  }

  #homepage-title-fake {
    color: black;
    // position: absolute;
    opacity: 0;
    // left: 0px;
    // top: 0px;
  }

  .typing {
    position: absolute;
    left: 0;
    top: 0;
  }

  .page-header-carousel-images {
    max-width: 80%;
    max-height: 60%
    // filter: grayscale(100%);
  }

  // .page-header-carousel-images:hover {
  //  filter: grayscale(0%); 
  // }

  #home-explore.home-description-container .description,
  #home-micronarrazioni.home-description-container .description, {
    padding: var(--s3) var(--dgrid-body-margin);
  }

  @keyframes image_movement {
    0% {transform: translateY(0);}
    50% {transform: translateY(4rem);}
    100% {transform: translateY(0);}
  }

  @keyframes reset {
    100% {transform: translate(0, 0);}
  }

  .page-header-carousel-images.animation {
    animation: image_movement 1s;
  }

  .page-header-carousel-images.reset {
    animation: reset .2s forwards;
  }

  .home-two-blocks {
    gap: calc(var(--dgrid-col-w) + var(--dgrid-gap));
  }

  .home-two-blocks .home-grid-section-text {
    width: 100%;
  }

  #home .home-two-blocks > .media {
    position: relative;
    max-width: unset;
    // aspect-ratio: 1/1;
    width: calc(100% + var(--dgrid-body-margin));
  }

  .home-two-blocks.image-right > .media{
    right: calc(var(--dgrid-body-margin) * -1);
    left: 0;
  }

  .home-two-blocks.image-left > .media {
    left: calc(var(--dgrid-body-margin) * -1);
    right: 0;
  }

  .home-two-blocks.home-grid-section-text {
    --dgrid-col: 2;
    width: calc((100% - (var(--dgrid-col) - 1)*var(--dgrid-gap))/var(--dgrid-col));
    align-self: center;
    justify-self: center;
  }

  #home .page-header .page-header-carousel-container {
    top: 0;
  }

  .page-header .page-header-carousel-container img {
    z-index: -2;
  }

  #home .page-header {
    top: 0;
  }

  .page-header .home-page.page-title {
    margin-right: 0;
    align-self: center;
    margin: var(--s3) 0 0 0;
  }

  .scroll-image {
    position: absolute;
    bottom: calc(calc( var(--s5) / 2 ) * -1);
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  #home iframe {
    height: 5rem;
  }
  
  @media (max-width: $breakpoint-m) {
    #home {
      --flow-space-horizontal: var(--s2);
      --flow-space: var(--s2);
    }

    .scroll-image {
      bottom: calc(calc( var(--s0) / 2 ) * -1);
    }
    .home-description-container .description {
      // padding: var(--s1) var(--s4) var(--s0) var(--s2);
      // margin-: 0; 
    }

    .home-description-container {
      grid-template-columns: repeat(auto-fit, 100%);
    }

    .home-two-blocks {
      gap: var(--s2);
    }

    .home-two-blocks > .media {
      width: 100%;
      right: 0 !important;
      left: 0 !important;
    }

    .home-two-blocks.home-grid-section-text {
      --dgrid-col: 1;
    }

    .page-header .home-page.page-title {
      order: -1
    }
    .page-header .home-page.page-title {
      margin: 0; 
    }
    .scroll-image {
      bottom: calc(var(--s2) * -1);
    }

    #home iframe {
			height: 7rem;
		}
  }

  @media (max-width: $breakpoint-sm) {

    #home {
      --flow-space: var(--s4);

      .dgrid-minw-wrap {
        --dgrid-gap: var(--s3);
      }
    }

    #home > section:nth-child(3) {
      margin-top: var(--s4);
    }

    .home-description-container > .description-image,
    .home-description-container .button-base {
      padding: var(--s2);
    }

    .dgrid-minw-wrap {
      --dgrid-min-width: 100%;
    }

    #home iframe {
			height: auto;
		}
  }
</style>
